'use strict';

var STORE_RESULTS_CONTAINER_SELECTOR = '[data-js="store-locator-results"]';
var processInclude = require('sfra/util');

function loadGoogleApi(googleMapsApi) {
    var script = document.createElement('script');
    script.src = googleMapsApi;
    script.async = true;
    script.onload = function () {
        processInclude(require('./storefinder'));
    };
    document.head.appendChild(script);
}

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Retrieves store data based on location and updates the store results.
 *
 */
function getStores() {
    var url = $('.storefinder').attr('data-action');
    var location = $('[data-js="detect-location"]').data('mylocation');
    var urlParams = {
        lat: location['lat'],
        long: location['long']
    };
    url = appendToUrl(url, urlParams);

    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        success: function (response) {
            $('[data-js="map-canvas"]').attr('data-has-google-api', !!response.googleMapsApi);
            loadGoogleApi(response.googleMapsApi);
            $('[data-js="map-canvas"]').attr('data-locations', response.locations);
            $('[data-js="detect-location"]').attr('data-action', response.actionUrl);
            $('[data-js="store-locator-results"]').attr('data-search-key', JSON.stringify(response.searchKey));
            $(STORE_RESULTS_CONTAINER_SELECTOR).attr('data-has-results', !!response.stores);
            $('[data-js="store-locator-results"]').attr('data-radius', response.radius);
        }
    });
}
$(document).ready(() => {
    getStores();
});
