'use strict';

/* globals google, $ */

var util = require('./util/util');
var STORE_RESULTS_CONTAINER_SELECTOR = '[data-js="store-locator-results"]';
var STORE_LOCATOR_ERROR_SELECTOR = '[data-js="store-locator-error"]';
var isMobileOrTablet = window.matchMedia("only screen and (max-width: 1024px)").matches;
var countryCode = $(STORE_RESULTS_CONTAINER_SELECTOR).data('search-key').countryCode;
var map;

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Renders the results of the search
 * @param {Object} data - Response from the server
 */
function updateStoresResults(data) {
    var hasResults = data.stores.length > 0;
    if (!hasResults) {
        $('[data-js="store-locator-no-results"]').show();
    } else {
        $('[data-js="store-locator-no-results"]').hide();
        var city = data.stores[0].city;
        $('[data-js="search-store-location-storefinder"]').val(city);
    }
}

/**
 * Get api postal code by the input value
 * @param {HTMLElement} element - the target html element
 * @returns {boolean} false to prevent default event
 */
function searchStore() {
    var $form = $('.store-locator');
    var $postalCodeInput = $form.find('[name="postalCode"]');
    var postalCodeValue = $postalCodeInput.val().trim();

    if (postalCodeValue.length === 0) {
        $(STORE_LOCATOR_ERROR_SELECTOR).find('[data-js="search-error"]').remove();
        $(STORE_LOCATOR_ERROR_SELECTOR).prepend('<p class="text-is-center" data-js="search-error">' + $(STORE_LOCATOR_ERROR_SELECTOR).data('errors').noSearchQuery + '</p>');
        return false;
    }

    var APISearchQuery = $('[data-postal=api-search-result]').val();
    var postalCode = APISearchQuery || postalCodeValue;
    return postalCode;
}

module.exports = {
    init: function () {
        if (!$(STORE_RESULTS_CONTAINER_SELECTOR).data('has-results')) {
            $('[data-js="store-locator-no-results"]').show();
        }
    },

    initStorefinderGoogleMap: function () {
        var searchKeys = $('[data-js="store-locator-results"]').data('search-key');
        var latlng = new google.maps.LatLng(searchKeys.lat, searchKeys.long);

        var mapOptions = {
            scrollwheel: false,
            center: latlng,
            fullscreenControl: false
        };

        map = new google.maps.Map($('[data-js="map-canvas"]')[0], mapOptions);

        var input;
        if ($('[data-js="search-store-location-storefinder"]').length > 0) {
            input = $('[data-js="search-store-location-storefinder"]')[0];
        } else if (isMobileOrTablet) {
            input = $('[data-js="appointment-stores-search-mobile"]')[0];
        } else {
            input = $('[data-js="appointment-stores-search"]')[0];
        }
        const options = {
            fields: ['address_components', 'geometry'],
            types: ['locality', 'sublocality', 'postal_code', 'political', 'street_address'],
            strictBounds: false,
            componentRestrictions: {
                country: countryCode
            }
        };

        const autocomplete = new google.maps.places.Autocomplete(input, options);
        autocomplete.bindTo('bounds', map);
        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            if (place.geometry) {
                var addressComponents = place.address_components;
                var addressSearch = util.postalCodeOrCity(addressComponents, countryCode);
                $('[data-postal=api-search-result]').val(addressSearch);
            }
            var inputValue = searchStore();
            if (inputValue) {
                var submitButtonHref = $('[data-js="submit-button"]').attr('href');
                window.location.href = submitButtonHref + '&autocompleteInputField=' + encodeURIComponent(inputValue);
            }
        });
    },

    submitMakeAppointment: function () {
        // storefinder PD make appointment button handler
        $('[data-js="submit-button"]').on('click', function (e) {
            e.preventDefault();
            var inputValue = searchStore();
            if (inputValue) {
                var submitButtonHrefValue = $(this).attr('href');
                window.location.href = submitButtonHrefValue + '&autocompleteInputField=' + encodeURIComponent(inputValue);
            }
        });
    },

    detectLocation: function () {
        $('[data-js="detect-location"]').on('click', function (e) {
            e.preventDefault();

            $('[name="postalCode"]').val('');
            $('[data-postal=api-search-result]').val('');

            var $myLocationBtn = $(this);
            var $detectLocationButton = $('[data-js="detect-location"]');
            var url = $detectLocationButton.data('action');
            var radius = $(STORE_RESULTS_CONTAINER_SELECTOR).data('radius');
            var isHearingAid = true;
            var isGlasses = true;
            var isLenses = true;
            var urlParams = {
                radius: radius,
                isHearingAid: isHearingAid,
                isGlasses: isGlasses,
                isLenses: isLenses,
                lat: $myLocationBtn.data('mylocation')['lat'],
                long: $myLocationBtn.data('mylocation')['long']
            };

            if (!navigator.geolocation) {
                url = appendToUrl(url, urlParams);
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        updateStoresResults(data);
                    }
                });
            }

            navigator.geolocation.getCurrentPosition(function (position) {
                if (position.coords) {
                    urlParams.lat = position.coords.latitude;
                    urlParams.long = position.coords.longitude;
                }

                url = appendToUrl(url, urlParams);
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        updateStoresResults(data);
                    }
                });
            });
        });
    },

    search: function () {
        $('[data-js="search-store-location-storefinder"]').keypress(function (e) {
            if (e.which === 13) {
                e.preventDefault();
                var inputValue = searchStore();
                if (inputValue) {
                    var submitButton = $('[data-js="submit-button"]').attr('href');
                    window.location.href = submitButton + '&autocompleteInputField=' + encodeURIComponent(inputValue);
                }
            }
        });
    }
};
